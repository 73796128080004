<template>
    <div class="cyber-content">
        <div class="top-content">
            <el-button type="primary" class="create-btn" plain @click="createNew">创建策略与分析</el-button>
        </div>
        <el-table class="customTable" :data="customList" style="width: 100%; flex: 1;" height="1%"
                  :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#666',fontSize: '16px'}"
                  :cell-style="{fontSize: '16px',color: '#333333',height: '70px'}">
            <el-table-column prop="title" label="标题" align="left" width="200"></el-table-column>
            <el-table-column prop="create_time" label="创建时间" align="center"></el-table-column>
            <el-table-column prop="content" label="操作" align="center" width="200">
                <template slot-scope="scope">
                    <el-link type="primary" :underline="false" @click="viewRow(scope.row)">
                        查看
                    </el-link>
                    <el-link type="success" :underline="false" @click="editRow(scope.row)">
                        编辑
                    </el-link>
                    <el-link type="danger" :underline="false" @click="delRow(scope.row)">
                        删除
                    </el-link>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
    import {drill_result_list, del_drill_result, exam_result_list, del_exam_result} from '@/utils/apis'

    export default {
        name: "Index",
        data() {
            return {
                customList: [],
                opId: Number(this.$route.query.id) || null,
                courseId: Number(this.$route.query.course_id) || null,
                chapter: Number(this.$route.query.chapter) || null,
                node: Number(this.$route.query.node) || null,
                platform: 0,//0是实训，1是考试
            }
        },
        created() {
            this.platform = this.$route.meta.type;
            this.getList();
        },
        methods: {
            getList() {
                if (this.platform == 0) {
                    let params = {
                        op_id: this.opId,
                        course_id: this.courseId,
                        chapter: this.chapter,
                        node: this.node,
                    }
                    drill_result_list(params).then(res => {
                        if (res.data.data) {
                            this.customList = res.data.data
                        } else {
                            this.customList = []
                        }
                    })
                } else {
                    exam_result_list().then(res => {
                        if (res.data.data) {
                            this.customList = res.data.data
                        } else {
                            this.customList = []
                        }
                    })
                }
            },
            createNew() {
                if (this.platform == 0) {
                    this.$router.push({
                        path: '/student/trainCenter/startTraining/cyberCustomerService/createManagement',
                        query: {
                            id: this.opId,
                            course_id: this.courseId,
                            chapter: this.chapter,
                            node: this.node,
                        }
                    })
                } else {
                    this.$router.push({
                        path: '/student/exam/cyberCustomerService/createManagement',
                        query: {
                            id: this.opId,
                            course_id: this.courseId,
                            chapter: this.chapter,
                            node: this.node,
                        }
                    })
                }
            },
            viewRow(row) {
                if (this.platform == 0) {
                    this.$router.push({
                        path: '/student/trainCenter/startTraining/cyberCustomerService/ManagementDetail',
                        query: {
                            id: row.id
                        }
                    })
                } else {
                    this.$router.push({
                        path: '/student/exam/cyberCustomerService/ManagementDetail',
                        query: {
                            id: row.id
                        }
                    })
                }
            },
            editRow(row) {
                if (this.platform == 0) {
                    this.$router.push({
                        path: '/student/trainCenter/startTraining/cyberCustomerService/createManagement',
                        query: {
                            id: this.opId,
                            course_id: this.courseId,
                            chapter: this.chapter,
                            node: this.node,
                            editId: row.id
                        }
                    })
                } else {
                    this.$router.push({
                        path: '/student/exam/cyberCustomerService/createManagement',
                        query: {
                            id: this.opId,
                            course_id: this.courseId,
                            chapter: this.chapter,
                            node: this.node,
                            editId: row.id
                        }
                    })
                }
            },
            delRow(row) {
                this.$confirm('是否删除', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    customClass: 'green-message-box',
                    type: 'warning'
                }).then(() => {
                    let param = {
                        id: row.id
                    }
                    if (this.platform == 0) {
                        del_drill_result(param).then((res) => {
                            this.$message.success('删除成功');
                            this.getList();
                        }).catch((err) => {
                            console.log(err);
                        })
                    } else {
                        del_exam_result(param).then((res) => {
                            this.$message.success('删除成功');
                            this.getList();
                        }).catch((err) => {
                            console.log(err);
                        })
                    }
                }).catch(() => {
                    this.$message.error('已取消删除');
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .cyber-content {
        margin: 13px 20px;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .create-btn {
        height: 40px;
        background: #FFFFFF;
        border: 1px solid #2DC079;
        border-radius: 4px;
        color: #2DC079;
        cursor: pointer;
        text-align: center;
        font-size: 16px;
    }


    .customTable {
        margin-top: 10px;
    }
</style>